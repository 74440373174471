import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "2012 - 2016",
      title: "Bachelor in Software Engineering",
      place: "University of Management and Technology - UMT",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the dummy text.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "Jan 2018 - Present",
      title: "Director Engineering  @ Ezyagent / Ezytext      ",
      place: "ezyagent.com",
      desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    },
    {
      yearRange: "Jan 2018 - Present",
      title: "Lead Engineer at Zentap",
      place: "zentap.com",
      desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    },
    {
      yearRange: "Jan 2018 - Present",
      title: "Teamlead ROR at TkXel",
      place: "tkxel.com",
      desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    },
    {
      yearRange: "Apr 2016 - Jan 2018",
      title: "Software Engineer (ROR and Angular js)",
      place: "Engin Technologies",
      desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    },
    {
      yearRange: "Apr 2016 - Jan 2018",
      title: "Remote Ruby on Rails Developer",
      place: "Online Pro LLC Wyoming USA",
      desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    },
    {
      yearRange: "Mar 2015 - Present",
      title: "Founder at priceflip.pk",
      place: "priceflip.pk",
      desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    },
  ];

  const skills = [
    {
      name: "Ruby on Rails",
      percent: 98,
    },
    {
      name: "Rspec",
      percent: 97
    },
    {
      name: "Redis",
      percent: 96
    },
    {
      name: "Sidekiq",
      percent: 93
    },
    {
      name: "Angular JS",
      percent: 95,
    },
    {
      name: "Vue JS",
      percent: 92,
    },
    {
      name: "Node JS",
      percent: 93,
    },
    {
      name: "React JS",
      percent: 90,
    },
    {
      name: "Shopify Apps",
      percent: 93,
    },
    {
      name: "Bootstrap",
      percent: 91,
    },
    {
      name: "CI/CD",
      percent: 92,
    },
    {
      name: "Git",
      percent: 91,
    },
    {
      name: "Tailwind",
      percent: 94,
    },
  ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Resume</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          A summary of My Resume
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    {/* <p className="text-muted">{value.desc}</p> */}
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} 
                      {/* / {value.yearRange} */}
                    </p>
                    {/* <p className="text-muted">{value.desc}</p> */}
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Skills</h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            // href={resumeFile}
            download
          >
            Download CV
          </a>
        </p>
      </div>
    </section>
  );
};

export default Resume;
