import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FAQs = () => {
  const faqsData = [
    {
      question: "What qualifications and experience should I look for in a developer?",
      answer:
        "Certainly, when hiring a developer, it's essential to consider their qualifications and experience. I hold a software engineering degree, which has equipped me with a strong foundation in IT. I have been working in the field for 8 years, during which I've successfully delivered projects similar to yours. My expertise lies in different frameworks ans languages, which will be valuable for your project's requirements.",
    },
    {
      question: "How do you estimate project timelines and costs?",
      answer:
        "Estimating project timelines and costs involves careful consideration of various factors. I follow a detailed approach, breaking down the project into smaller tasks and assessing the complexity of each. This helps me provide an accurate timeline for each phase of development. I also consider potential challenges that might arise and build in some buffer time to account for unforeseen delays. As for costs, I factor in the time, resources, and technology requirements to give you a transparent and fair estimate.",
    },
    {
      question: "Can you provide examples of your previous work or references?",
      answer:
        "Absolutely, I'd be happy to showcase my previous work. I have a portfolio that highlights a range of projects I've contributed to, including wearlively, ezyagent. These examples demonstrate my ability to handle various technologies and project scopes. Additionally, I can provide references from previous clients who can vouch for my professionalism, expertise, and effective communication throughout the development process.",
    },
    {
      question: "What's your preferred communication and collaboration process?",
      answer:
        "I value open and clear communication. I prefer regular check-ins to provide updates on the project's progress, discuss any challenges, and gather your feedback. We can use communication tools like email, project management platforms, and video calls to stay in touch. I'm also available for scheduled meetings to address any concerns or changes you'd like to discuss. For seamless collaboration, I'm open to using tools that you're comfortable with.",
    },
    {
      question: "How do you handle bugs, updates, and ongoing maintenance?",
      answer:
        "Addressing bugs and providing updates is a vital part of the development process. I follow a thorough debugging process to identify and fix any issues that arise. Once the project is live, I offer ongoing maintenance services to ensure that the software remains up-to-date and functional. I'm committed to responding promptly to any maintenance requests and keeping the software running smoothly over time. We can discuss retainer options for continued support.",
    },
  ];

  return (
    <section id="faq" className="section bg-light">
      <div className="container">
        <div className="row gy-5">
          <div className="col-lg-6 order-1 order-lg-0 wow fadeInUp">
            {/* Heading */}
            <p className="text-center text-lg-start mb-2">
              <span className="bg-primary text-dark px-2">FAQ</span>
            </p>
            <h2 className="text-10 fw-600 text-center text-lg-start mb-5">
              Have any questions?
            </h2>
            {/* Heading end*/}

            <Accordion flush defaultActiveKey="0">
              {faqsData.length > 0 &&
                faqsData.map((faq, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    ></Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
          <div
            className="col-lg-6 d-flex align-items-center justify-content-center order-0 order-lg-1 wow fadeIn"
            data-wow-delay="1s"
          >
            {" "}
            <img
              className="img-fluid"
              src="images/faq.png"
              title="FAQ"
              alt="faq"
            />{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
