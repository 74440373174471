import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    // DETAILED: "Details",
    // MOCKUPS: "Mockups",
    // YOUTUBE: "Youtube Videos",
    // VIMEO: "Vimeo Videos",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = [
    {
      title: "LIVELY",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "One of the world’s biggest online store of women products. RoR based stack, development and enhancements to existing code-base. we developed custom web app for automation of their ambassadors program to increase sales . which help to quickly get commision based sales and track them according. Custom Web portal for managing their Vendors and inventory , so that they have enough inventory topped up always.Fetching their Shopify orders and custom reporting on sales & products.",
        client: "Lively",
        technologies: "ROR, React",
        industry: "IT",
        date: "July 16, 2019",
        url: {
          name: "www.wearlively.com",
          link: "https://www.wearlively.com",
        },

        sliderImages: [
          "images/projects/lively-square.png",
        ],
      },

      thumbImage: "images/projects/lively-square.png",

      categories: [filters.DETAILED],
    },
    {
      title: "PROFICIO",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Working directly with their CTO, we’re helping them enhance and add in new features to their website. Developed in Node, Angular and Mongo DB.",
        client: "Proficio",
        technologies: "iOS, HTML5, CSS3, PHP, Java",
        industry: "IT",
        date: "July 16, 2019",
        url: {
          name: "www.proficio.com",
          link: "https://www.proficio.com",
        },

        sliderImages: [
          "images/projects/profocio-square.png",
        ],
      },
      

      thumbImage: "images/projects/profocio-square.png",

      categories: [filters.DETAILED],
    },
    {
      title: "Zentap",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Zentap enables real estate agents to thrive with software that streamlines their real estate marketing to help gain more leads and close more business.",
        client: "Zentap",
        technologies: "ROR, Angular",
        industry: "IT",
        date: "July 16, 2019",
        url: {
          name: "www.zentap.com",
          link: "https://www.zentap.com",
        },

        sliderImages: [
          "images/projects/zentap-square.png",
        ],
      },
      

      thumbImage: "images/projects/zentap-square.png",

      categories: [filters.DETAILED],
    },
    {
      title: "Green top farms",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Green Top Farms delivers healthy catering and meals to institutions and homes across New York City. We create healthy recipes using seasonal produce from local farms to deliver sustainable lunches, dinners, and snacks to you.",
        client: "Green top farms",
        technologies: "ROR",
        industry: "IT",
        date: "July 16, 2019",
        url: {
          name: "www.greentop.farm",
          link: "https://www.greentop.farm",
        },

        sliderImages: [
          "images/projects/green-top-farms-square.png",
        ],
      },
      

      thumbImage: "images/projects/green-top-farms-square.png",

      categories: [filters.DETAILED],
    },
    {
      title: "Halyard",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Halyard, part of the Owens & Minor Global Products Division, is focused on moving care forward. Our $1.2 billion portfolio of market-leading brands includes HALYARD, MEDICHOICE and MEDICAL ACTION are sold in more than 90 countries. Halyard’s broad portfolio offers the right product for each need, backed by best-in-class clinical expertise and more support at points of care. Our product standardization opportunities combined with supply chain expertise help drive efficiencies and protect healthcare providers from risks and disruptions in care.",
        client: "Halyard",
        technologies: "iOS, HTML5, CSS3, PHP, Java",
        industry: "IT",
        date: "July 16, 2019",
        url: {
          name: "www.halyardhealth.com",
          link: "https://www.halyardhealth.com",
        },

        sliderImages: [
          "images/projects/halyard-square.png",
        ],
      },
      

      thumbImage: "images/projects/halyard-square.png",

      categories: [filters.DETAILED],
    },
    {
      title: "Ideawake",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "An easy to use, highly configurable idea management platform that makes it easy to capture, evaluate, and take action on ideas from those who know the most about your organization.",
        client: "Ideawake",
        technologies: "iOS, HTML5, CSS3, PHP, Java",
        industry: "IT",
        date: "July 16, 2019",
        url: {
          name: "www.ideawake.com",
          link: "https://www.ideawake.com",
        },

        sliderImages: [
          "images/projects/ideawaka-square.png",
        ],
      },
      

      thumbImage: "images/projects/ideawaka-square.png",

      categories: [filters.DETAILED],
    },
    {
      title: "pHin",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "An easy to use, highly configurable idea management platform that makes it easy to capture, evaluate, and take action on ideas from those who know the most about your organization.",
        client: "pHin",
        technologies: "VueJS",
        industry: "IT",
        date: "July 16, 2019",
        url: {
          name: "NA",
          link: "#",
        },

        sliderImages: [
          "images/projects/phin-square.png",
        ],
      },
      

      thumbImage: "images/projects/phin-square.png",

      categories: [filters.DETAILED],
    },
    {
      title: "Ezyagent",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Ezyagent is an insurance crm software that provides automation solution for Insurance agents by automatically handling all their important client notifications.",
        client: "Ezyagent",
        technologies: "ROR, VueJS",
        industry: "IT",
        date: "July 16, 2019",
        url: {
          name: "www.ezyagent.com",
          link: "https://www.ezyagent.com",
        },

        sliderImages: [
          "images/projects/ezyagent-square.png",
        ],
      },
      

      thumbImage: "images/projects/ezyagent-square.png",

      categories: [filters.DETAILED],
    },
    {
      title: "Fantasy Middleware",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "An easy to use, highly configurable idea management platform that makes it easy to capture, evaluate, and take action on ideas from those who know the most about your organization.",
        client: "Fantasy Middleware",
        technologies: "ROR",
        industry: "IT",
        date: "July 16, 2019",
        url: {
          name: "Fantasy Middleware",
          link: "https://fantasy-middleware.herokuapp.com/",
        },

        sliderImages: [
          "images/projects/fantasy-middleware-square.png",
        ],
      },
      

      thumbImage: "images/projects/fantasy-middleware-square.png",

      categories: [filters.DETAILED],
    },
    {
      title: "Mega Stores",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "MEGASTORES is at the forefront of the mens fashion and drops fresh styles daily. Discover the latest trends in men's clothes, shoes, accessories, and more at MEGASTORES.",
        client: "Mega Stores",
        technologies: "Shopify, Liquid, ROR (custom app)",
        industry: "IT",
        date: "July 16, 2019",
        url: {
          name: "megastorescy.com/",
          link: "https://megastorescy.com/",
        },

        sliderImages: [
          "images/projects/megastore-square.png",
        ],
      },
      

      thumbImage: "images/projects/megastore-square.png",

      categories: [filters.DETAILED],
    },
    {
      title: "Puppy wash",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "The World's Leading Automated Dog Bath. Press a button, and your dog is clean within minutes from the comfort of your own home. Easy to store and use. Works inside (bathtub, floor) and outside (lawn, driveway) your house.",
        client: "Puppy wash",
        technologies: "Shopify, Liquid",
        industry: "IT",
        date: "July 16, 2019",
        url: {
          name: "puppywash.com/",
          link: "https://puppywash.com/",
        },

        sliderImages: [
          "images/projects/puppywash-square.png",
        ],
      },
      

      thumbImage: "images/projects/puppywash-square.png",

      categories: [filters.DETAILED],
    },
    {
      title: "Timbits Sports",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "At Tim Hortons, we provide opportunities for kids to learn a new sport, play in a league, make new friends, and just be a kid. Timbit Sports supports over 300,000 players across Canada each season",
        client: "Tim Hortons",
        technologies: "ROR, JS",
        industry: "IT",
        date: "July 16, 2019",
        url: {
          name: "timbitssports.com/",
          link: "https://www.timbitssports.com/",
        },

        sliderImages: [
          "images/projects/timbits-sports-square.png",
          "images/projects/timbits-hockey-square.png",
        ],
      },
      

      thumbImage: "images/projects/timbits-sports-square.png",

      categories: [filters.DETAILED],
    },
    // {
    //   title: "Mockups Design 1",
    //   type: types.IMAGE,

    //   thumbImage: "images/projects/project-2.jpg",

    //   categories: [filters.MOCKUPS],
    // },
    // {
    //   title: "YouTube Video",
    //   type: types.VIDEO,
    //   video: {
    //     vimeo: false,
    //     id: "PMNnEEEacCg",
    //   },
    //   thumbImage: "images/projects/project-3.jpg",

    //   categories: [filters.YOUTUBE],
    // },
    // {
    //   title: "Vimeo Video",
    //   type: types.VIDEO,
    //   video: {
    //     vimeo: true,
    //     id: "259411563",
    //   },

    //   thumbImage: "images/projects/project-4.jpg",
    //   categories: [filters.VIMEO],
    // },
    // {
    //   title: "Mockups Design 2",
    //   type: types.IMAGE,

    //   thumbImage: "images/projects/project-6.jpg",

    //   categories: [filters.MOCKUPS],
    // },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  return (
    <>
      <section id="portfolio" className={"section bg-light"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Portfolio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Some of my most recent projects
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            <p className="text-primary text-8">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p>
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
